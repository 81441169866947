import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import { PrimaryButton } from '@pmi.web/react-common';
import { CustomerRegistrationForm } from '@pmi.web/react-user-forms';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { useCustomerRegistration } from 'hooks/useCustomerRegistration';
import { useProspectOnboard } from 'hooks/useProspectOnboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { startSignIn } from 'services/AuthService';

export function CustomerRegistrationV2() {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const appInsights = useAppInsightsContext();
  const { redirectUrl, prospectData, resumeToken } = useRegistrationContext();

  const [formData, setFormData] = useState({
    ...prospectData,
    locale: language,
    sponsor: prospectData?.sponsor ?? '',
    sponsorId: prospectData?.sponsor ?? '',
    address: {
      ...prospectData?.address,
      country: prospectData?.address?.country ?? ''
    }
  });

  const {
    data: registrationResult,
    // error, // TODO: do something with these backend errors
    isLoading: isLoadingCustomerRegistration,
    register
  } = useCustomerRegistration();

  const {
    data: onboardingResult,
    // error, // TODO: do something with these backend errors
    isLoading: isLoadingProspectOnboarding,
    onboard
  } = useProspectOnboard();

  const isLoading =
    isLoadingCustomerRegistration || isLoadingProspectOnboarding;
  const result = registrationResult || onboardingResult;

  const trackCustomerRegistrationDone = useTrackEvent(
    appInsights,
    'CustomerRegistrationDone',
    {}
  );

  useEffect(() => {
    if (result) {
      trackCustomerRegistrationDone({});
      appInsights.core.flush(false);

      startSignIn({
        nonceToken: result.otpNonce,
        prospectId: result.id,
        currentState: JSON.stringify({
          redirectUrl: redirectUrl,
          locale: language
        })
      });
    }
  }, [result]);

  useEffect(() => {
    setFormData(state => ({ ...state, locale: language }));
  }, [language]);

  if (isLoading || result) {
    return (
      <div className="min-h-[150px] flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <CustomerRegistrationForm
        country={formData.address.country}
        language={formData.locale}
        formId="customer-registration-form"
        onSubmit={data => {
          const prospect = {
            ...formData,
            ...data
          };

          setFormData(prospect as any);

          if (prospectData?.id) {
            onboard(prospect, resumeToken);
          } else {
            register(prospect);
          }
        }}
        // TODO: make this optional.. is not needed right now
        onValidation={() => void 0}
        defaultValues={formData}
      />

      <div className="w-full flex justify-end mt-md">
        <PrimaryButton
          disabled={isLoading}
          type="submit"
          form="customer-registration-form"
        >
          {t('Submit')}
        </PrimaryButton>
      </div>
    </>
  );
}
