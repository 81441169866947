import { PrimaryButton } from '@pmi.web/react-common';
import { EmailInput } from '@pmi.web/react-inputs';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { useBoolean } from 'hooks/useBoolean';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as userManagement from 'services/UserManagementService';

import { EmailConfirmedSuccessfully } from './EmailConfirmedSuccessfully';

const EMAIL_REGEX = /.+@.+\..+/;

export function EmailConfirmationPage() {
  const { prospectData, redirectUrl, emailRecoveryHint } =
    useRegistrationContext();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>();
  const [isSubmitting, { setTrue: startSubmission, setFalse: stopSubmission }] =
    useBoolean(false);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const onEmailInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue && inputValue.length > 0 && EMAIL_REGEX.test(inputValue)) {
      setEmail(inputValue);
    } else {
      setEmail(undefined);
    }
  };

  const onSubmit = async () => {
    startSubmission();

    if (!email) {
      return;
    }

    try {
      const resp = await userManagement.confirmEmail(
        prospectData?.id ?? '',
        email,
        redirectUrl
      );
      if (!resp.ok) {
        // TODO: handle error case
        return;
      }

      setShowSuccess(true);
    } finally {
      stopSubmission();
    }
  };

  if (showSuccess) {
    return <EmailConfirmedSuccessfully />;
  }

  return (
    <div className="space-y-sm">
      <h1>{t('Confirm your email address')}</h1>

      {isSubmitting && (
        <div className="min-h-[150px] flex items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      {!isSubmitting && (
        <>
          <p>{t('Please enter the email address used during registration.')}</p>
          {emailRecoveryHint && emailRecoveryHint.length > 0 && (
            <p>{t("Here's a hint: {{hint}}", { hint: emailRecoveryHint })}</p>
          )}

          <EmailInput
            value={email}
            label={t('Email address')}
            id="email"
            aria-label={t('Email address')}
            required
            onChange={onEmailInputChange}
          />

          <PrimaryButton disabled={!email} onClick={onSubmit}>
            {t('Submit')}
          </PrimaryButton>
        </>
      )}
    </div>
  );
}
