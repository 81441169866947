import {
  ProspectData,
  CredentialsData,
  CustomerData,
  PropsectResumeData
} from '../types/models';

export async function getProspect(
  prospectId: string,
  continueToken?: string
): Promise<ProspectData | PropsectResumeData> {
  const response = await fetch(
    continueToken && continueToken.length > 0
      ? `${process.env.REACT_APP_USERMANAGEMENT_API}/users/prospects/customer/${prospectId}?continuation=${continueToken}`
      : `${process.env.REACT_APP_USERMANAGEMENT_API}/users/prospects/customer/${prospectId}`
  );

  const textResponse = await response.text();
  let jsonResponse;

  try {
    jsonResponse = await JSON.parse(textResponse);
  } catch (e) {
    //
  }

  if (response.ok) {
    return jsonResponse ?? {};
  }

  throw response;
}

export async function onboardProspect(
  prospect: ProspectData,
  continueToken?: string
) {
  return fetch(
    continueToken && continueToken.length > 0
      ? `${
          process.env.REACT_APP_USERMANAGEMENT_API
        }/prospects/customer/onboard/${
          prospect.address?.country ?? ''
        }?continuation=${continueToken}`
      : `${
          process.env.REACT_APP_USERMANAGEMENT_API
        }/prospects/customer/onboard/${prospect.address?.country ?? ''}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(prospect)
    }
  );
}

export async function registerCustomer(customer: CustomerData) {
  return fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/signup/${
      customer.address?.country ?? ''
    }?sponsorId=${customer.sponsorId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(customer)
    }
  );
}

export async function confirmEmail(
  prospectId: string,
  email: string,
  redirectUrl?: string
) {
  return fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/users/prospects/customer/resume`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ prospectId, email, redirectUrl })
    }
  );
}

/// Check if SetCredentials access token is still valid
export function validateSetCredentialsToken(accessToken: string) {
  return fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/users/credentials/preflight/${accessToken}`
  );
}

export function setCredentials(credentials: CredentialsData) {
  return fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/users/credentials`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    }
  );
}
