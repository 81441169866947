import { PrimaryButton } from '@pmi.web/react-common';
import { fireConfettiGun } from 'effects';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { openWindow } from 'utils/window';

export function RegistrationCompletePage() {
  const { t } = useTranslation();

  useEffect(() => {
    fireConfettiGun('red');
  }, []);

  return (
    <div className="flex flex-col items-center gap-md">
      <h1>{t('Congratulations!')}</h1>
      <p>{t('Your registration is completed.')}</p>

      <PrimaryButton
        onClick={() => {
          openWindow('pmebusiness', process.env.REACT_APP_LINK_WEBSHOP ?? '');
        }}
      >
        {t('Go to shop')}
      </PrimaryButton>
    </div>
  );
}
